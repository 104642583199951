
<div style="display: inline-block">
  <div @click="visible = true" class="header-right-icon header-action">
    <Icon type="md-more" size="20" />
  </div>
  <Drawer :title="$t('themeTitle')" v-model="visible" width="300">
    <div class="theme-setting">
      <div class="theme-item-content">
        <h3 class="title">{{ $t("wholeSetting") }}</h3>
        <div class="checkbox-content">
          <Tooltip transfer placement="top" :content="$t('lightblue')">
            <div
              class="checkbox-item checkbox-item-primary"
              @click="changeMainTheme('primary')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="mainTheme == 'primary'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('white')">
            <div
              class="checkbox-item checkbox-item-light"
              @click="changeMainTheme('light')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="mainTheme == 'light'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('topNav')">
            <div
              class="checkbox-item checkbox-item-nav-black checkbox-item-nav"
              @click="changeMainTheme('topNav')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="mainTheme == 'topNav'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('darkMode')">
            <div
              class="checkbox-item checkbox-item-darkMode"
              @click="changeMainTheme('darkMode')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                color="#177ddc"
                v-if="mainTheme == 'darkMode'"
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <Divider />
      <div class="theme-item-content">
        <h3 class="title">{{ $t("sideMenu") }}</h3>
        <div class="checkbox-content">
          <Tooltip transfer placement="top" :content="$t('darkblue')">
            <div
              class="checkbox-item checkbox-item-darkblue"
              @click="changeMenuTheme('darkblue')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="menuTheme == 'darkblue'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('dark')">
            <div
              class="checkbox-item checkbox-item-black"
              @click="changeMenuTheme('black')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="menuTheme == 'black'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('middle')">
            <div
              class="checkbox-item checkbox-item-dark"
              @click="changeMenuTheme('dark')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="menuTheme == 'dark'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('white')">
            <div
              class="checkbox-item checkbox-item-side-light"
              @click="changeMenuTheme('light')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="menuTheme == 'light'"
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <div class="theme-item-content">
        <h3 class="title">{{ $t("navMenu") }}</h3>
        <div class="checkbox-content">
          <Tooltip transfer placement="top" :content="$t('darkblue')">
            <div
              class="checkbox-item checkbox-item-nav-darkblue checkbox-item-nav"
              @click="changeNavTheme('darkblue')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="navTheme == 'darkblue'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('dark')">
            <div
              class="checkbox-item checkbox-item-nav-black checkbox-item-nav"
              @click="changeNavTheme('black')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="navTheme == 'black'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('lightblue')">
            <div
              class="checkbox-item checkbox-item-nav-primary checkbox-item-nav"
              @click="changeNavTheme('primary')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="navTheme == 'primary'"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('white')">
            <div
              class="checkbox-item checkbox-item-nav-light checkbox-item-nav"
              @click="changeNavTheme('light')"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="navTheme == 'light'"
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <Divider />
      <div style="margin-bottom: 24px">
        <h3 class="title">{{ $t("navMode") }}</h3>
        <div class="checkbox-content">
          <Tooltip transfer placement="top" :content="$t('rightTop')">
            <div
              class="checkbox-item checkbox-item-topright checkbox-layout-item"
              @click="changeFixNav(false)"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="!fixNav"
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" :content="$t('topFix')">
            <div
              class="checkbox-item checkbox-item-top checkbox-layout-item"
              @click="changeFixNav(true)"
            >
              <Icon
                type="md-checkmark"
                class="checkbox-select-icon"
                v-if="fixNav"
              />
            </div>
          </Tooltip>
        </div>
        <div class="theme-list">
          <div class="theme-list-item">
            <div class="item-title">{{ $t("navType") }}</div>
            <Select
              :value="navType"
              @on-change="changeNavType"
              style="width: 150px"
              size="small"
            >
              <Option value="1">{{ $t("horizontal") }}</Option>
              <Option value="2">{{ $t("singleDrop") }}</Option>
              <Option value="3">{{ $t("iconBreadcrumb") }}</Option>
            </Select>
          </div>
          <div class="theme-list-item" v-if="navType == 1">
            <div class="item-title">{{ $t("showIcon") }}</div>
            <i-switch
              :value="showNavMenuIcon"
              @on-change="changeShowNavMenuIcon"
              size="small"
            />
          </div>
          <div class="theme-list-item" v-if="navType == 2 || navType == 3">
            <div class="item-title">{{ $t("dropMenu") }}</div>
            <Select
              :value="navShowType"
              @on-change="changeNavShowType"
              style="width: 150px"
              size="small"
            >
              <Option value="app">{{ $t("appStyle") }}</Option>
              <Option value="list">{{ $t("listStyle") }}</Option>
            </Select>
          </div>
        </div>
      </div>
      <Divider />
      <div style="margin-bottom: 24px">
        <h3 class="title">{{ $t("contentArea") }}</h3>
        <div class="theme-list">
          <div class="theme-list-item">
            <div class="item-title">{{ $t("enableTags") }}</div>
            <i-switch
              :value="showTags"
              @on-change="changeShowTags"
              size="small"
            />
          </div>
          <div class="theme-list-item">
            <div class="item-title">{{ $t("showFold") }}</div>
            <i-switch
              :value="showFold"
              @on-change="changeShowFold"
              size="small"
            />
          </div>
          <div class="theme-list-item">
            <div class="item-title">{{ $t("showLogo") }}</div>
            <i-switch
              :value="showLogo"
              @on-change="changeShowLogo"
              size="small"
            />
          </div>
          <div class="theme-list-item">
            <div class="item-title">{{ $t("showSearchMenu") }}</div>
            <i-switch
              :value="showSearch"
              @on-change="changeShowSearch"
              size="small"
            />
          </div>
          <div class="theme-list-item">
            <div class="item-title">{{ $t("showFooter") }}</div>
            <i-switch
              :value="showFooter"
              @on-change="changeShowFooter"
              size="small"
            />
          </div>
          <div class="theme-list-item">
            <div class="item-title">{{ $t("sideMenuWidth") }}（px）</div>
            <InputNumber
              :min="0"
              :max="500"
              :value="menuWidth"
              style="width: 100px"
              size="small"
              @on-change="changeMenuWidth"
            />
          </div>
        </div>
      </div>
      <Divider />
      <div style="margin-bottom: 24px">
        <h3 class="title">{{ $t("otherSetting") }}</h3>
        <div class="theme-list">
          <div class="theme-list-item">
            <div class="item-title">{{ $t("weakMode") }}</div>
            <i-switch
              :value="weakMode"
              @on-change="changeWeakMode"
              size="small"
            />
          </div>
        </div>
      </div>
      <Alert type="warning" show-icon>{{ $t("themeInfo") }}</Alert>
    </div>
  </Drawer>
</div>
